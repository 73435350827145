@import "./dark.scss";

.heading{
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
    height: 6rem;
    align-items: center;
    overflow: visible;

    .heading-text{
        font-size: 2.5rem;
        color: black;
        font-weight: 700;
        white-space: nowrap;

        &.dark {
            color: $light;
        }
    }

    .background-text{
        position: absolute;
        font-size: 5rem;
        color: rgba(0, 0, 0, 0.05);
        left: -3%;
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        z-index: -1;

        &.dark {
            color: rgba(255, 255, 255, 0.05);
        }
    }
}