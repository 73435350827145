@import "./dark.scss";

#about{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &.container{
        align-items: flex-start;
        padding: 0 3vw;
    }

    .heading{
        left: 3%;
        margin-bottom: 2rem;
    }

    #aboutContent{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    
    #aboutText{
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 40%;
        position: relative;
        margin-bottom: 2rem;

        &.dark {
            color: rgba(255, 255, 255, 0.5);
        }

        h1{
            font-size: 2rem;
        }

        p{
            width: 100%;
            
            span{
                font-weight: 700;
            }
        }

        .buttons-container{
            margin-top: 1rem;

            a{
                background-color: rgba(0, 0, 0, 0.05);

                &.dark {
                    background-color: white;
                    color: $dark;
                    border-color: $light;
                }
            }
            
            #resume{
                padding: 0.1em 0.5em;
                font-family: 'Titillium Web', sans-serif;
                font-weight: 700;
                font-size: 1.1rem;
                border: 2px solid rgb(19, 95, 236);
                text-align: center;
                color: white;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.8);
                transition: background-color 0.1s;
                display: flex;
                align-items: center;
                gap: 0.8em;
                width: 70%;
                justify-content: center;
                
                .icon{
                    font-size: 1.3rem;
                }
                
                &:hover{
                    background-color: rgba(19, 95, 236, 0.85);
                }
            }
        }

    }

    #images-techStack{
        width: 50%;
        transform: translateY(-10%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        #tech-stack{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .stack{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 1em;
                justify-content: center;
                grid-template-columns: repeat(4, 1fr);

                .tech{
                    width: 15%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 5px;
                    padding: 0.5em 0.3em;
                    justify-content: space-between;
                    background-color: rgba(0, 0, 0, 0.03);
                    transition: transform 0.1s;

                    &.dark {
                        background-color: rgba(255, 255, 255, 0.95);
                    }

                    img{
                        width: 40px;
                    }

                    span{
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }
        }
    }

    .images-container{
        position: relative;
        display: flex;
        justify-content: center;
        width: 50%;
        aspect-ratio: 1 / 1;
    }

    .img-container{
        position: absolute;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

        &.animate{
            animation: scaleImg forwards 0.3s;
        }

        img{
            width: 100%;
        }

        &:nth-child(2) {
            transform: rotate(20deg);
            z-index: -1;
            scale: 0.9;
            box-shadow: none;
        }

        &:nth-child(3) {
            transform: rotate(30deg);
            z-index: -2;
            scale: 0.9;
            box-shadow: none;
        }
    }
}

@keyframes scaleImg {
    0%{
      scale: 0.9;
      opacity: 0;
    }
    100%{
        scale: 1;
        opacity: 1;
        z-index: 0;
    }
}

@media screen and (min-width: 1281px) {
    #app{
        overflow-y: auto;
    }
    #container{
        width: 75%;
    }

    #aboutContainer{
        width: 100%;
    }

    #about{
        flex-direction: column;
        gap: 2rem;

        &.container{
            width: 100%;
            margin-bottom: 2rem;
            padding-inline: 0;
            gap: 0;
        }


        #aboutContent{
            flex-direction: row;
            justify-content: space-between;

        }

        #aboutText{
            width: 45%;
            max-width: 600px;

            p{
                font-size: 1.05rem;
                text-align: left;
            }

            .buttons-container{
                margin: 1.5rem 0;
                gap: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;

                a{
                    font-size: 1.05rem;
                    width: 48%;
                }

                #resume{
                    flex-grow: 1;
                }
            }
        }

        #images-techStack{
            width: 45%;
            gap: 3rem;
            justify-content: center;
            margin-bottom: 0;
            transform: translateY(-15%);

            .images-container{
                width: 70%;
                max-width: 350px;
                align-items: center;
                margin: 2rem auto;
                justify-content: center;
    
                .img-container{
                    width: 80%;
                }
            }

            #tech-stack{
                width: 100%;
                
                .stack{
                    margin-bottom: 2rem;

                    .tech{
                        width: 22%;
                        max-width: 100px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    #app{
        overflow-y: auto;
    }

    #container{
        width: 85%;
    }

    #aboutContainer{
        width: 100%;
    }

    #about{
        flex-direction: column;
        gap: 2rem;

        &.container{
            width: 100%;
            margin-bottom: 2rem;
            padding-inline: 0;
        }


        #aboutContent{
            flex-direction: row;
            justify-content: space-between;
        }

        #aboutText{
            width: 50%;

            p{
                font-size: 1.05rem;
                text-align: left;
            }

            .buttons-container{
                margin: 1.5rem 0;
                gap: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;

                a{
                    font-size: 1.05rem;
                    width: 48%;
                }

                #resume{
                    flex-grow: 1;
                }
            }
        }

        #images-techStack{
            width: 45%;
            gap: 3rem;
            justify-content: unset;
            margin-bottom: 0;
            transform: translateY(0);

            .images-container{
                width: 75%;
                max-width: 350px;
                align-items: unset;
                margin: 2rem auto;
                justify-content: flex-end;
    
                .img-container{
                    width: 80%;
                }
            }

            #tech-stack{
                width: 100%;
                
                .stack{
                    margin-bottom: 2rem;

                    .tech{
                        width: 30%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 851px) and (max-width: 1024px) {
    #app{
        overflow-y: auto;
    }
    
    #about{
        flex-direction: column;
        gap: 2rem;



        #aboutContent{
            flex-direction: column;
        }

        #aboutText{
            width: 100%;

            p{
                font-size: 1.1rem;
                text-align: center;
            }

            .buttons-container{
                margin: 1.5rem 0;
                gap: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;

                a{
                    font-size: 1.05rem;
                    width: 48%;
                }

                #resume{
                    flex-grow: 1;
                }
            }
        }

        #images-techStack{
            width: 100%;
            gap: 5rem;
            margin-bottom: 0;
            transform: translateY(0);

            .images-container{
                width: 90%;
                max-width: 350px;
                align-items: center;
                margin: 2rem auto;
    
                .img-container{
                    width: 80%;
                }
            }

            #tech-stack{
                .stack{
                    margin-bottom: 2rem;

                    .tech{
                        width: 20%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 370px) and (max-width: 510px) {
    #aboutText{
        width: 100%;

        p{
            font-size: 1.1rem;
            text-align: center;
        }

        .buttons-container{
            margin: 3rem auto 1rem auto;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;

            a{
                font-size: 1.05rem;
                width: 49%;
            }

            #resume{
                flex-grow: 1;
            }
        }
    }
}

@media screen and (min-width: 581px) and (max-width: 850px) {
    #about{
        flex-direction: column;
        gap: 2rem;

        #aboutContent{
            flex-direction: column;
        }

        .heading{
            margin-bottom: 0;
            margin: 2rem auto 0 auto;

            .heading-text{
                width: 100%;
                text-align: center;
            }

            .background-text{
                text-align: center;
            }
        }

        #aboutText{
            width: 100%;

            p{
                font-size: 1.1rem;
                text-align: center;
            }

            .buttons-container{
                margin: 1.5rem 0;
                gap: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;

                a{
                    font-size: 1.05rem;
                    width: 48.5%;
                }

                #resume{
                    flex-grow: 1;
                }
            }
        }

        .images-container{
            width: 90%;
            max-width: 350px;
            align-items: center;
            margin: 2rem auto;

            .img-container{
                width: 80%;
            }
        }

        #images-techStack{
            width: 100%;
            gap: 1rem;
            margin-bottom: 3rem;
            transform: translateY(0);

            #tech-stack{
                .stack{
                    margin-bottom: 2rem;

                    .tech{
                        width: 30%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 461px) and (max-width: 580px) {
    #about{
        flex-direction: column;
        gap: 2rem;

        #aboutContent{
            flex-direction: column;
        }

        .heading{
            margin-bottom: 0;
            margin: 2rem auto 0 auto;

            .heading-text{
                width: 100%;
                text-align: center;
            }

            .background-text{
                text-align: center;
            }
        }

        #aboutText{
            width: 100%;

            p{
                font-size: 1.1rem;
                text-align: center;
            }

            .buttons-container{
                margin: 1.5rem 0;
                gap: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;

                a{
                    font-size: 1.05rem;
                    flex: 1;
                }

                #resume{
                    flex-grow: 1;
                }
            }
        }

        .images-container{
            width: 90%;
            max-width: 350px;
            align-items: center;
            margin: 2rem auto;

            .img-container{
                width: 80%;
            }
        }

        #images-techStack{
            width: 100%;
            gap: 1rem;
            margin-bottom: 3rem;
            transform: translateY(0);

            #tech-stack{
                .stack{
                    margin-bottom: 2rem;

                    .tech{
                        width: 30%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 371px) and (max-width: 460px) {
    #about{
        flex-direction: column;
        gap: 2rem;

        #aboutContent{
            flex-direction: column;
        }

        .heading{
            margin-bottom: 0;
            margin: 2rem auto 0 auto;

            .heading-text{
                width: 100%;
                text-align: center;
            }

            .background-text{
                text-align: center;
            }
        }

        #aboutText{
            width: 100%;

            p{
                font-size: 1.1rem;
                text-align: center;
            }

            .buttons-container{
                margin: 1.5rem 0;
                gap: 1rem;
                justify-content: space-between;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                flex-direction: column;

                a{
                    font-size: 1.05rem;
                    width: 100%;
                }

                #resume{
                    flex-grow: 1;
                    width: 100%;
                }
            }
        }

        .images-container{
            width: 90%;
            max-width: 350px;
            align-items: center;
            margin: 2rem auto;

            .img-container{
                width: 80%;
            }
        }

        #images-techStack{
            width: 100%;
            gap: 1rem;
            margin-bottom: 3rem;
            transform: translateY(0);

            #tech-stack{
                .stack{
                    margin-bottom: 2rem;

                    .tech{
                        width: 30%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 370px) {
    #about{
        flex-direction: column;
        gap: 0rem;
        overflow-x: hidden;

        #aboutContent{
            flex-direction: column;
        }

        .heading{
            margin-bottom: 0;
            margin: 2rem auto 0 auto;
            left: 0;
            width: 100%;
            overflow: hidden;

            .heading-text{
                width: 100%;
                text-align: center;
            }

            .background-text{
                text-align: center;
            }
        }

        #aboutText{
            width: 100%;

            p{
                font-size: 1.1rem;
                text-align: center;
            }

            .buttons-container{
                margin: 3rem auto 1rem auto;
                flex-direction: column;
                width: 100%;
                padding: 0 3%;

                a{
                    width: 100%;
                    font-size: 1.2rem;
                }

                #resume{
                    width: 100%;
                }
            }
        }

        .images-container{
            width: 90%;
            max-width: 350px;
            margin: 2rem auto;
            align-items: center;

            .img-container{
                width: 80%;
            }
        }

        #images-techStack{
            width: 100%;
            gap: 1rem;
            margin-bottom: 3rem;
            transform: translateY(0);

            #tech-stack{
                .stack{
                    margin-bottom: 2rem;
                    .tech{
                        width: 45%;
                    }
                }
            }
        }
    }
}