@import "./dark.scss";

#logo{
    cursor: pointer;
    font-weight: 700;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: rgba(0, 0, 0, 0.8);
    
    img{
        width: 50px;
    }
    
    span{
        padding-top: 0.3em;
        font-family: 'Josefin Sans', sans-serif;
        color: rgba(0, 0, 0, 0.8);

        &.dark{
            color: $light;
        }
    }
}

@media screen and (min-width: 901px) {
    #logo{
        span{
            width: max-content;
            font-size: 1.9rem;
        }
    }
}
@media screen and (max-width: 900px) {
    #logo{
        span{
            display: none;
        }
    }
}