@import "./dark.scss";

footer{
    border-top: 2px solid rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(250, 250, 250);

    &.dark {
        background-color: $dark;

        p{
            color: $light;
        }
    }

    p{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
}

@media screen and (max-width: 850px) {
    footer{
        p{
            width: 95%;
        }
    }
}