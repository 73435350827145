@import "./dark.scss";

#theme-button {
    padding: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 2px solid black;
    overflow-y: hidden;
    cursor: pointer;
    position: absolute;
    right: 2rem;

    .icon.theme{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 1.4rem;
        transition: transform 0.2s;
        color: black;

        &.dark {
            color: $dark;
        }

        &.animate-in{
            animation: animateIn forwards 0.3s;
        }

        &.animate-out{
            animation: animateOut forwards 0.2s;
        }
    }
}

@keyframes animateIn {
    from {
        transform: translateY(-130%);
        opacity: 0;
    } to {
        transform: translateY(0%);
        opacity: 1;
    }
}
@keyframes animateOut {
    from {
        transform: translateY(0%);
        opacity: 0.4;
    } to {
        transform: translateY(130%);
        opacity: 0;
    }
}