@import "./dark.scss";

#sidebar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 120px;
    position: fixed;
    left: 0;
    top: 40%;
    min-width: 3vw;
    background-color: rgba(0, 0, 0, 0.9);

    &.dark {
        background-color: $light;
    }

    .sidebar-icon{
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 2rem;
        text-decoration: none;
        color: white;

        &.dark {
            color: $dark;

            &:hover{
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        &#linkedin{
            color: rgba(19, 95, 236, 1);

            .icon{
                background-color: white;
                border-radius: 5px;
            }
        }

        &:hover{
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    #sidebar{
        min-width: auto;

        .sidebar-icon{
            padding: 0.5em 0.35em;
        }
    }   
}

@media screen and (min-width: 851px) and (max-width: 1024px) {
    #sidebar{
        min-width: auto;
        display: none;

        .sidebar-icon{
            padding: 0.5em 0.35em;
        }
    }   
}

@media screen and (max-width: 850px) {
    #sidebar{
        min-width: auto;
        display: none;

        .sidebar-icon{
            padding: 0.5em 0.35em;
        }
    }

    #navMobile{
        #sidebar{
            display: flex;
            flex-direction: row;
            height: auto;
            width: 90%;
            justify-content: space-between;
            position: unset;
            background-color: transparent;

            .sidebar-icon{
                padding: 0.3em;
                height: 100%;
                width: 45%;
                border: 2px solid rgb(0, 0, 0, 0.9);
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}