@import "./dark.scss";

#navbar{
    width: 100%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 999;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(250, 250, 250);
    height: 4rem;

    &.dark{
        background-color: $dark;
    }

    >#logo{
        display: none;
    }

    #hamburger{
        display: none;
    }

    #navDesktop{
        padding-inline: 4em;
        display: flex;
        align-items: center;
    }
}

nav{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15%;
    gap: 4rem;
    flex: 1;

    .flex1{
        flex: 1;
    }

    ul{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: flex-end;

        .nav-link{
            padding: 0.3em 1em;
            text-decoration: none;
            color: black;
            font-size: 1.2rem;
            font-weight: 600;

            &.dark{
                color: rgba(255, 255, 255, 0.7);
            }

            &:hover{
                color: rgba(0, 0, 0, 0.7);

                &.dark{
                    color: $light;
                }
            }
        }     
    }

    #resume{
        padding: 0.1em 0.5em;
        font-family: 'Titillium Web', sans-serif;
        font-weight: 700;
        font-size: 1.1rem;
        border: 2px solid rgb(19, 95, 236);
        text-align: center;
        color: white;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.8);
        transition: background-color 0.1s;
        display: flex;
        align-items: center;
        gap: 0.8em;
        width: max-content;
        
        .icon{
            font-size: 1.3rem;
        }
        
        &:hover{
            background-color: rgba(19, 95, 236, 0.85);
        }
    }
}

#navMobile{
    display: none;
}

@media screen and (min-width: 1281px) {
    #navbar{
        #theme-button{
            position: unset;
            
        }
    }
}

@media screen and (min-width: 851px) and (max-width: 1280px) {
    #navbar{
        nav{
            justify-content: space-between;
        }

        .flex1{
            display: none;
        }

        #resume{
            display: none;
        }
    }
}

@media screen and (max-width: 850px) {
    #navbar {
        >#logo{
            display: flex;
        }

        > #theme-button{
            position: absolute;
            right: 1rem;
        }

        #hamburger{
            display: flex;
        }

        #navDesktop{
            display: none;
        
            #resume{
                position: absolute;
                right: 2%;
                display: none;
            }

            .flex1{
                display: none;
            }
        }

    }

    #navMobile{
        display: flex;
        justify-content: center;
        padding-inline: 5vw;
        position: fixed;
        top: 0;
        z-index: 900;
        background-color: whitesmoke;
        transition: transform 0.3s;
        overflow-y: hidden;
        height: 100vh;
        flex-direction: column;
        gap: 10vh;
        padding-inline: 0;

        &.open{
            transform: translateY(0);
            pointer-events: all;
        }
        
        &.closed{
            pointer-events: none;
            transform: translateY(-100%);
        }

        &.dark{
            background-color: $dark;
        }
        
        ul{
            width: 100%;
            flex-direction: column;
            transition: top 0.3s;

            .nav-link{
                padding: 0.5em 1em;
                font-size: 1.7rem;
                width: 100%;
                text-align: center;

                &:active{
                    background-color: rgba(0, 0, 0, 0.05);
                }

                &.dark{
                    color: $light;
                }
            }
        }

        #github-linkedin-buttons{
            padding: 2em 0.5em;
            background-color: rgba(0, 0, 0, 0.05);
            display: flex;
            width: 100%;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2rem;

            &.dark {
                background-color: rgba(0, 0, 0, 0.5);
                color: $light;
            }

            h2{
                font-weight: 600;
                font-size: 1.5rem;
            }

            #sidebar{
                max-width: 300px;

                .sidebar-icon{
                    &.dark{
                        color: $light;
                    }

                    &#linkedin{
                        color: rgb(19, 95, 236);
                    }
                }
            }
        }

    }
}

@media screen and (min-width: 900px) and (max-width: 1110px) {
    #navbar{
        #navDesktop{
            padding-inline: 2em;
        }
    }
}

@media screen and (min-width: 851px) {
    #navbar{
        padding-inline: 5vw;
        
        > .flex1{
            flex: none;
        }
        
        > #theme-button{
            display: none;
        }
    }
}

@media screen and (max-width: 380px) {
    #navbar{
        padding-inline: 5vw;
    }
}