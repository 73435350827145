@import "./dark.scss";

#projects{
    flex-direction: column;

    &.container{
        margin: 10vh 0;
    }

    .heading{
        .background-text{
            left: -1.5%;
        }
    }

    #projects-list{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 2em;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        .project{
            border: 2px solid black;
            width: 45%;
            background-color: rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;
            align-items: center;

            &.dark {
                background-color: rgba(50, 50, 50, 0.95);

                .project-name{
                    h2{
                        color: $light;
                    }

                    span{
                        color: rgba(255, 255, 255, 0.5);
                    }
                }

                .project-description{
                    color: rgba(255, 255, 255, 0.5);
                }

                .project-tech{
                    .tech{
                        background-color: rgba(255, 255, 255, 0.8);
                    }
                }
            }

            .buttons-container{
                margin: 0 auto;
                width: 100%;
                padding-inline: 1em;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                
                a{
                    width: 45%;
                    max-width: 250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1em;
                    white-space: nowrap;

                    .icon{
                        font-size: 1.3rem;
                    }
        
                }
            }

            .project-name{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0.1em 0.5em;

                h2{
                    font-size: 1.4rem;
                }

                span{
                    font-weight: 600;
                    font-size: 1.2rem;
                    font-style: italic;
                    color: rgba(0, 0, 0, 0.3);
                    margin: 0 auto 1em auto;
                }
            }

            .project-description{
                text-align: center;
                width: 90%;
                margin: 0 auto 2rem auto;
            }

            hr{
                width: 90%;
                margin: 0 auto 1rem auto;
                border: 1px solid rgba(0, 0, 0, 0.4);
            }

            .project-tech{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                flex-wrap: wrap;
                padding: 0.3rem 1rem;
                margin-bottom: 0.5rem;

                .tech{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    height: 5rem;
                    min-width: 15%;
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    padding: 0.5em 0.8em;

                    span{
                        font-size: 0.85rem;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.7);
                        text-align: center;
                    }
                }

                img{
                    width: 30px;
                }
            }
        }
    }

    #moreProjects{
        text-decoration: none;
        color: white;
        background-color: rgba(0, 0, 0, 0.9);
        padding: 0.5em 1em;
        font-size: 1.2rem;
        border: 2px solid rgba(0, 0, 0, 0.9);
        display: flex;
        align-items: center;
        gap: 1em;
        width: 70%;
        justify-content: center;
        margin-bottom: 2rem;

        &:hover, &:active{
            background-color: rgba(0, 0, 0, 0.8);
        }

        .icon{
            font-size: 1.5rem;
        }

        &.dark{
            border: 2px solid $light;
        }
    }
}

@media screen and (min-width: 851px) and (max-width: 1024px) {
    #projects-list{
        .project{
            .buttons-container{
                gap: 0.5em;

                .link{
                    flex: 1;
                }
            }
        }
    }
}

@media screen and (min-width: 851px) {
    #projects{
        &.container{
            padding: 4vh 0;
            margin: 0 auto;
        }

        .heading{
            width: 95%;
            margin: 2rem auto 4rem auto;

            .heading-text, .background-text{
                text-align: left;
                width: 100%;
            }
        }

        #projects-list{
            padding: 0.5em;
            justify-content: center;

            .project{
                width: 45%;
                flex-grow: 1;
                max-width: 700px;
                justify-content: space-around;

                .project-tech{
                    margin: 0;
                    padding: 1em;
                    .tech{
                        width: 80px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 451px) and (max-width: 850px) {
    #projects{
        &.container{
            padding: 0;
        }

        .heading{
            width: 95%;
            margin: 0 auto;

            .heading-text, .background-text{
                text-align: center;
                width: 100%;
            }
        }

        #projects-list{
            padding: 0.5em;

            .project{
                width: 100%;

                .project-tech{
                    margin: 0;
                    padding: 1em;
                    .tech{
                        width: 80px;
                    }
                }
            }
        }

        #moreProjects{
            width: 95%;
        }
    }
}

@media screen and (max-width: 450px) {
    #projects{
        &.container{
            padding: 0;
        }
        
        .heading{
            width: 95%;
            margin: 0 auto;

            .heading-text, .background-text{
                text-align: center;
                width: 100%;
            }
        }

        #projects-list{
            flex-direction: column;
            gap: 2rem;
            padding: 0.5em;

            .project{
                width: 100%;
                .buttons-container{
                    gap: 1em;
                    flex-direction: column;
    
                    .link{
                        width: 80%;
                        max-width: 100%;
                    }
                }

                .project-tech{
                    margin: 0;
                    padding: 1em;
                    .tech{
                        width: 80px;
                    }
                }
            }
        }

        #moreProjects{
            width: 95%;
            font-size: 1.1rem;
        }
    }
}